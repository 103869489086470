import { ReactComponent as CaretGlyph } from '@/assets/images/svg/glyphs/caret.svg';
import { ReactComponent as SearchGlyph } from '@/assets/images/svg/glyphs/search.svg';
import { ReactComponent as FrontPageWordmark } from '@/assets/images/svg/logos/front/front-page-wordmark.svg';
import Link from '@/components/Link';
import { Container } from '@/components/layout/Container';
import MobileNavigation, { MobileNavigationProps } from '@/components/navigation/MobileNavigation';
import { FRONT_PAGE_HOME, FRONT_PAGE_SEARCH } from '@/constants/routes';
import { GlobalContext } from '@/context';
import gtm from '@/lib/gtm';
import { maybeNotSet, notEmpty } from '@/lib/typescript-helpers';
import { COLORS } from '@/styles/color';
import { Z_INDEX } from '@/styles/layout';
import { applyBreakpointPadding, mediaBreakpointDown, mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, MouseEventHandler, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PRIMARY_NAV_HEIGHT } from './PrimaryNavigation';

const NAV_HEIGHT = 64;

export const BLOG_NAV_HEIGHT = NAV_HEIGHT + PRIMARY_NAV_HEIGHT;
const ANALYTICS_CATEGORY = 'Blog Navigation';

// #region - Styled Components

interface NavigationElementProps {
  isOpen: boolean;
}

const Navigation = styled.nav<NavigationElementProps>`
  position: sticky;
  top: ${remCalc(PRIMARY_NAV_HEIGHT)};
  left: 0;
  z-index: ${Z_INDEX.PRIMARY_NAV - 1};
  width: 100%;
  background-color: ${COLORS.GRAY.TINT};
  box-shadow: rgb(0 27 56 / 8%) 0px 4px 16px;
  color: ${(props) => (props.isOpen ? COLORS.BLACK : `var(--blog-navigation-primary-color, ${COLORS.BLACK})`)};
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;
`;

const NavigationContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${remCalc(NAV_HEIGHT)};
`;

const RightNavigation = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: ${remCalc(20)};
`;

const LogoLink = styled.a`
  position: relative;
  display: inline-block;
  height: ${remCalc(26)};
  top: 2px;

  svg {
    height: 100%;

    path {
      fill: currentColor;
      transition: fill 0.3s ease-in-out;
    }
  }
`;

const SearchButton = styled.a`
  background-color: transparent;
  width: ${remCalc(36)};
  height: ${remCalc(36)};
  margin-right: ${remCalc(-6)};
  padding: ${remCalc(6)};
  border-radius: ${remCalc(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${COLORS.GRAY.HIGHLIGHT};
  }
`;

const AnchorLinkContainer = styled.nav`
  overflow: auto;
  flex: 1;
  margin: 0 ${remCalc(40)};

  ${mediaBreakpointDown('tablet')} {
    display: none;
  }
`;

const AnchorLinkList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
`;

const AnchorLink = styled.a`
  flex: 0;
  white-space: nowrap;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  font-weight: ${FONT_WEIGHTS.NORMAL};
  border-bottom: 2px solid;
  border-color: transparent;
  cursor: pointer;
  ${customTextSize(14, 24)}
`;

const MobileNavWrapper = styled.nav`
  ${applyBreakpointPadding};

  position: sticky;
  top: ${remCalc(BLOG_NAV_HEIGHT)};
  left: 0;
  width: 100%;
  height: calc(100vh - ${remCalc(BLOG_NAV_HEIGHT)});

  padding-bottom: 2rem;

  overflow-y: auto;

  background-color: ${COLORS.WHITE};
  z-index: ${Z_INDEX.PRIMARY_NAV - 2};

  ${mediaBreakpointUp('desktop')} {
    display: none;
  }
`;

interface MobileToggleButtonProps {
  menuOpen: boolean;
}

const MobileToggleButton = styled.button<MobileToggleButtonProps>`
  background-color: transparent;
  width: ${remCalc(36)};
  height: ${remCalc(36)};
  margin-right: ${remCalc(-6)};
  padding: ${remCalc(6)};
  border-radius: ${remCalc(5)};

  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${COLORS.GRAY.HIGHLIGHT};
  }

  svg {
    width: 100%;
    transform: rotateX(${(props) => (props.menuOpen ? '0deg' : '180deg')});
    transition: transform 0.3s ease-in-out;
  }

  ${mediaBreakpointUp('desktop')} {
    display: none;
  }
`;

// #endregion

const BlogNavigation: FC = () => {
  const {
    navigation: { blogNav },
  } = useContext(GlobalContext);
  const [navOpen, setNavOpen] = useState(false);

  const onLogoClick: MouseEventHandler<HTMLAnchorElement> = () => {
    gtm.track('click', {
      element_type: 'link',
      element_label: 'Front Logo',
      location: ANALYTICS_CATEGORY,
    });

    /* Close the mobile nav */
    setNavOpen(false);
  };

  const navigationLinks = useMemo((): MobileNavigationProps['links'] => {
    const transformLinks = (links) =>
      links?.map((link) => ({
        id: link?.id,
        title: link?.title,
        href: link?.url,
        children: transformLinks(link?.children),
        hasColumns: false,
        color: null,
        icon: null,
        description: null,
      }));

    return transformLinks(blogNav);
  }, [blogNav]);

  return (
    <>
      <Navigation isOpen={navOpen}>
        <NavigationContainer>
          <Link href={FRONT_PAGE_HOME}>
            <LogoLink title="Front Page" onClick={onLogoClick}>
              <FrontPageWordmark title="Front Page wordmark" />
            </LogoLink>
          </Link>

          <AnchorLinkContainer>
            <AnchorLinkList>
              {blogNav?.filter(notEmpty)?.map((link) => (
                <Link href={link.url!} key={link.id}>
                  <AnchorLink
                    onClick={() => {
                      gtm.track('click', {
                        element_type: 'link',
                        element_label: maybeNotSet(link?.title),
                        location: ANALYTICS_CATEGORY,
                      });

                      setNavOpen(false);
                    }}
                  >
                    {link.title}
                  </AnchorLink>
                </Link>
              ))}
            </AnchorLinkList>
          </AnchorLinkContainer>

          <RightNavigation>
            {!navOpen && (
              <Link href={FRONT_PAGE_SEARCH}>
                <SearchButton
                  onClick={() => {
                    gtm.track('click', {
                      element_type: 'link',
                      element_label: 'Search Icon',
                      location: ANALYTICS_CATEGORY,
                    });
                  }}
                >
                  <SearchGlyph width={24} />
                </SearchButton>
              </Link>
            )}

            <MobileToggleButton
              menuOpen={navOpen}
              onClick={() => {
                const newState = !navOpen;

                setNavOpen(newState);

                gtm.track('click', {
                  element_type: 'button',
                  element_label: `${newState ? 'Opened' : 'Closed'} Nav`,
                  location: ANALYTICS_CATEGORY,
                });
              }}
            >
              <CaretGlyph />
            </MobileToggleButton>
          </RightNavigation>
        </NavigationContainer>
      </Navigation>

      {navOpen && (
        <MobileNavWrapper>
          <MobileNavigation
            analyticsCategory="Blog Mobile Navigation"
            onLinkClick={() => setNavOpen(false)}
            links={navigationLinks}
          />
        </MobileNavWrapper>
      )}
    </>
  );
};

export default BlogNavigation;
