import { ReactComponent as CaretGlyph } from '@/assets/images/svg/glyphs/caret.svg';
import PrimaryButton from '@/components/buttons/primary';
import Link from '@/components/Link';
import gtm from '@/lib/gtm';
import { maybeNotSet } from '@/lib/typescript-helpers';
import { ConditionalWrapper } from '@/lib/utils';
import { COLORS } from '@/styles/color';
import { marginY, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, MouseEventHandler, useState } from 'react';
import styled, { css } from 'styled-components';
import { hexToRGBA } from '@/styles/color/utils';

const BORDER_COLOR = '#BDC9D6';

// #region - Styled Components

const LinkList = styled.ul`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex: 1;
`;

const LinkListItem = styled.li`
  border-bottom: 1px solid ${BORDER_COLOR};
  white-space: nowrap;

  ${LinkList} &:last-of-type {
    border-bottom: none;
  }

  ${PrimaryButton} {
    ${marginY(16)}
  }
`;

const LinkStyle = css`
  ${customTextSize(16, 26)}
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
`;

const LinkListAnchor = styled.a`
  ${LinkStyle};

  align-items: center;
  cursor: pointer;
  display: inline-flex;
  width: 100%;

  span {
    position: relative;
  }

  ${LinkListItem} & {
    ${paddingY(16)};
  }
`;

const Submenu = styled.div<{ visible: boolean; pushLeft: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  background-color: ${COLORS.WHITE};
`;

const SubmenuCaret = styled.div<{ flip: boolean }>`
  ${customTextSize(0, 0)}

  display: inline-block;
  margin: 0 0 0 auto;
  transform: rotateX(${(props) => (props.flip ? 0 : 180)}deg);
`;

const CategoryColumn = styled.div`
  border-bottom: 1px solid ${BORDER_COLOR};

  ${Submenu} &:last-of-type {
    border-bottom: none;
  }
`;

const CategoryEyebrow = styled.p`
  ${customTextSize(12, 19)}
  ${marginY(30)}

  color: ${COLORS.RED.LOWLIGHT};
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHTS.BOLD};
  letter-spacing: ${remCalc(1.2)};
`;

const SubmenuList = styled.ul`
  margin: 0 ${remCalc(-16)};
`;

const SubmenuItem = styled.li`
  white-space: normal;

  a {
    display: block;

    background-color: transparent;
    padding: ${remCalc(16)};
    margin-bottom: ${remCalc(8)};
    border-radius: ${remCalc(10)};

    transition: background-color 0.15s ease-in-out;
  }

  &:hover a {
    background-color: ${hexToRGBA(COLORS.GRAY.HIGHLIGHT, 0.5)};
  }
`;

const SubmenuItemInner = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    ${customTextSize(14, 20)}
  }
`;

interface SubmenuItemTitleProps {
  hasDescription: boolean;
}

const SubmenuItemTitle = styled.span<SubmenuItemTitleProps>`
  ${customTextSize(16, 20)}

  font-weight: ${(props) => (props.hasDescription ? FONT_WEIGHTS.SEMIBOLD : FONT_WEIGHTS.NORMAL)};
  display: block;
  margin-bottom: ${(props) => (props.hasDescription ? remCalc(4) : 0)};
`;

// #endregion

type Link = {
  id: string | number;
  title: string;
  description?: string | null;
  href?: string | null;
  color?: string | null;
  icon?: {
    height: number;
    width: number;
    url: string;
  }[];
  children?: Link[];
};

export interface MobileNavigationProps {
  analyticsCategory: string;
  onLinkClick?: () => void;
  links: (Link & {
    hasColumns: boolean;
  })[];
}

const MobileNavigation: FC<MobileNavigationProps> = (props) => {
  const [expandedSubmenu, setExpandedSubmenu] = useState('');

  const primaryLinks = props.links.map((link) => {
    const onClick: MouseEventHandler<HTMLAnchorElement> = () => {
      const submenuId = String(link.id);

      gtm.track('click', {
        element_label: maybeNotSet(link.title),
        element_type: 'link',
        location: props.analyticsCategory,
      });

      if (props.onLinkClick && !link.children?.length) props.onLinkClick();

      setExpandedSubmenu(expandedSubmenu !== submenuId ? submenuId : '');
    };

    const onChildClick = (breadcrumbs: string[]) => {
      gtm.track('click', {
        element_label: `${link.title} > ${breadcrumbs.join(' > ')}`,
        element_type: 'link',
        location: props.analyticsCategory,
      });

      if (props.onLinkClick) props.onLinkClick();
    };

    const linkChildren = link.children || [];
    const hasSubmenu = linkChildren.length > 0;
    const submenuVisible = expandedSubmenu === link.id;
    const submenuColumns = link.hasColumns ? linkChildren : [{ children: linkChildren }];

    return (
      <LinkListItem key={link.id!}>
        {/* Only wrap with a link if it does not have a submenu */}
        <ConditionalWrapper
          condition={!!link.href && !hasSubmenu}
          wrapper={(children) => <Link href={link.href ?? '#'}>{children}</Link>}
        >
          <LinkListAnchor onClick={onClick}>
            <span>{link.title}</span>
            {hasSubmenu && (
              <SubmenuCaret flip={submenuVisible}>
                <CaretGlyph title={`Caret icon pointing ${submenuVisible ? 'up' : 'down'}`} width={24} />
              </SubmenuCaret>
            )}
          </LinkListAnchor>
        </ConditionalWrapper>

        {hasSubmenu && (
          <Submenu visible={submenuVisible} pushLeft={linkChildren.length > 1}>
            {/* Loop each category/column */}
            {submenuColumns.map((category) => (
              <CategoryColumn key={category?.id!}>
                {!!category?.title && <CategoryEyebrow>{category?.title}</CategoryEyebrow>}
                <SubmenuList>
                  {/* Loop each link */}
                  {category?.children?.map((child) => (
                    <SubmenuItem key={child?.id!}>
                      <Link href={child?.href ?? '#'}>
                        <a title={child?.title!} onClick={() => onChildClick([child?.title!])}>
                          <SubmenuItemInner>
                            <p>
                              <SubmenuItemTitle hasDescription={!!child?.description}>{child?.title}</SubmenuItemTitle>
                              {child?.description}
                            </p>
                          </SubmenuItemInner>
                        </a>
                      </Link>
                    </SubmenuItem>
                  ))}
                </SubmenuList>
              </CategoryColumn>
            ))}
          </Submenu>
        )}
      </LinkListItem>
    );
  });

  return <LinkList>{primaryLinks}</LinkList>;
};

export default MobileNavigation;
